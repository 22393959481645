import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Alur from "../components/images/alur.png"

const SubmissionPage = ({ data }) => (
  <Layout>
    <SEO title="Submission" />
    <Banner title="Submission"></Banner>

    <section id="content">
      <div className="content-wrap">
        <div className="container clearfix">

          <div className="col-md-12 col-lg-8">
            <div className="row clearfix">
              <div className="mb-0">
                <div className="heading-block" style={{ paddingTop: "40px" }}>
                  <p>
                    All of submitted manuscripts are subject to peer review
                    mechanism. Two reviewers are involved to review each
                    manuscript. Selected papers will be submitted in publisher
                    indexed by Scopus. However, authors should bear in mind that
                    final decision of all acceptances is in Scopus Publisher’s
                    side.
                  </p>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="mb-0">
                <h3>Submission Guide for Authors</h3>
                <p>
                  All manuscripts must be submitted online (not by email)
                  through website{" "}
                  <span className="color-apastd-secondary">
                    apastb.bankjaringandansel.id
                  </span>{" "}
                  — Please follow the procedure below:
                </p>
                <ol className="ol-size ml-4 mb-6">
                  <li>Create new account</li>
                  <li>Login to your account</li>
                  <li>
                    Make the event payment and confirm payment through our
                    online system (not by email)
                  </li>
                  <li>
                    Submit your abstracts through submission panel in the
                    website
                  </li>
                  <li>
                    Wait until the committee announce the abstract review result
                  </li>
                  <li>
                    Check your website submission panel regularly, in case the
                    committee request any revision
                  </li>
                  <li>Revise your abstract according to the given time</li>
                  <li>
                    After abstract acceptance and payment is confirmed send your
                    full paper (see Author Guideline) to the informed email
                    address for further review process
                  </li>
                  <li>
                    Check your email regularly (inbox or spam) in case the
                    committee request any revision
                  </li>
                  <li>
                    In conference day, please prepare your presentation in form
                    of power point presentation (PPT/PPTX)
                  </li>
                  <li>
                    The final decision regarding the publication will be
                    announced later
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-8">
            <div className="row clearfix">
                <div className="mb-0">
                  <p className="mb-0">Further details please download the workflow below :</p>
                  <img
                    src={Alur}
                    className="bor-tr mt-3 mb-3"
                    alt="ALUR"
                  />
                  <a href="https://drive.google.com/file/d/11azVWX5LsYVBSI_1AdhbaBzjvnioQ54P/view?usp=sharing" target="blank" className="button button-reg button-3d button-circle gradient-grey-orange mr-0 text-center mb-3 mt-5">
                        Download Workflow
                  </a>
                </div>
              </div>
          </div>

        </div>
      </div>
    </section>
  </Layout>
)

export default SubmissionPage
